Gri.module({
  name: 'header-alpha',
  ieVersion: null,
  $el: $('.header-alpha'),
  container: '.header-alpha',
  fn: function () {
    const $htmlBody = $('body, html');
    const $menuPanel = $('.menu-panel');
    const $searchBox = $('.search-box');
    $('#menu-icon').on('click', function () {
      if ($(this).hasClass('active')) {
        $(this).add($(this).find('.menu-icon-inner')).removeClass('active');
        $menuPanel.removeClass('mobile-active');
        $htmlBody.css({'overflow': '', width: ''});
      } else {
        $htmlBody.stop().animate({scrollTop: 0}, 300);
        const bodyWidth = $('body, html').width();
        $htmlBody.css({'overflow': 'hidden', width: bodyWidth + 'px'});
        $(this).add($(this).find('.menu-icon-inner')).addClass('active');
        $menuPanel.addClass('mobile-active');
      }
    });
    $('#search-button').on('click', function () {
      $searchBox.toggleClass('active');

      setTimeout(function () {
        $searchBox.find('input[type="search"]').focus().select();
      }, 400);
    });
    $('.form-search').on('submit', function () {
      if ($searchBox.find('input[type="search"]').val().length < 3) {
        return false;
      }
    });

    function subMenuAddHoverEvent() {
      $('.header-alpha .menu ul li').on('mouseenter',function () {
        let $this = $(this);
        setTimeout(() => {
          $this.find(' > ul').css('margin-left', -($this.find(' > ul').innerWidth() / 2) + 'px')
      }, 100);
      });
    }
    if(isIE(8, 'lte')) {
      subMenuAddHoverEvent();
    }


    function isIE( version, comparison ){
      const $div = $('<div style="display:none;"/>').appendTo($('body'));
      $div.html('<!--[if '+(comparison||'')+' IE '+(version||'')+']><a>&nbsp;</a><![endif]-->');
      let ieTest = $div.find('a').length;
      $div.remove();
      return ieTest;
    }

    function menuScrollController(){
      $('.menu-icon').on('click',function(){
        if($('.menu-icon').hasClass('active')){
          $('.header-alpha').css({
            position: 'fixed',
            background: 'white',
            zIndex: '99',
            width: '100%'
          });
        }else{
          $('.header-alpha').css({
            position: 'relative'
          });
        }
      });
    }

    function searchController(){
        var elAnimated = $('.search-animate');
        var elButton = $('.search-btn'); 
        var elSearchButton = $('.search-button i'); 
        var itemInput = $('.search-input');
        var searchControl = $('.search-submit');

        elButton.on('click', function(){
          elAnimated.addClass('active');
            $(itemInput ).animate({
                width : 200,
             }, 1000);
            $(elAnimated ).animate({ 
                width : 200,
             }, 1000);
           elButton.css('display', 'none'); 
        }); 
        elSearchButton.on('click', function(){ 
            if(itemInput.val() == ""){
              $(itemInput ).animate({
                width : 0,
             }, 1000);
            $(elAnimated ).animate({ 
                width : 0,
             }, 1000);
                elButton.css('display', 'block');  
                elAnimated.removeClass('active'); 
            }else{ 
               elButton.css('display', 'none'); 
              elAnimated.submit();
            }            
        });
    }
    menuScrollController();
    searchController();
  }
});
